import {ActionIcon, Badge, Button, CopyButton, Divider, Group, HoverCard, Image, List, Menu, Skeleton, Stack, Text, Tooltip, rem} from '@mantine/core';
import {IconDotsVertical, IconEdit, IconTrash, IconCircleXFilled, IconCircleCheckFilled, IconCheck, IconX, IconDatabaseOff, IconEye, IconCopy, IconRefresh, IconTrack, IconTruckDelivery, IconDashboard, IconCloudUpload, IconFlagDollar, IconCashBanknote, IconBrandWhatsapp, IconArrowsExchange} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import { client } from '../../lib/axiosClient';

import Cookies from "universal-cookie";
import { notifications } from '@mantine/notifications';
import { map, z } from 'zod';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListCompany from './../../listCompany.json';
import { useUsers } from '../../api';

const cookies = new Cookies(null, { path: '/' });

const customStyles: TableStyles = {
    table: {
        style: {
            minHeight: 380,
            border: "2px solid #E0E2E7",
            borderRadius: "8px"
        },
    },
    headRow: {
        style: {
            borderRadius: "8px 8px 0 0",
            ":nth-child(1)": {
                borderRadius: "0 8px 0 0"
            },
            ":nth-last-child(1)": {
                borderRadius: "8px 0 0 0"
            }
        },
    },
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
            background: "#F9F9FC",
            height: "56px",
            ":nth-child(1)": {
                background: "#F9F9FC",
            }
        }
    },
    cells: {
        style: {
            height: "60px"
        }
    }
};

type Props = {
    data?: any;
    loading?: boolean;
};

export const OrdersTabel = ({data, loading}: Props) => {
    const { t } = useTranslation();

    const getLastTrace = (data: any) => {
        if (!data) return []
        const length = data.length
        return data[length-1]
    }

    const columns = [
        {name: "اسم العميل", selector: (row: any, index: number) => row?.name, minWidth: "170px"},
        {name: "تاريخ اخر تحديث", selector: (row: any) => (
            getLastTrace(row?.timeLine)?.createdAt ? dayjs(getLastTrace(row?.timeLine)?.createdAt).locale("ar").fromNow() : dayjs(row?.createdAt).locale("ar").fromNow()
        ), minWidth: "150px" },
        {name: "اخر حالة", selector: (row: any) => (
            getLastTrace(row?.timeLine)?.status ? <Badge fw={'normal'}>{getLastTrace(row?.timeLine)?.status}</Badge> : <Badge fw={'normal'} >{"جديد"}</Badge>
        ), minWidth: "140px" },

        {name: "تاريخ التسجيل", selector: (row: any) => dayjs(row?.createdAt).locale("ar").fromNow(), minWidth: "140px", sortable: true, sortFunction: (a: any, b: any) => a?.createdAt > b?.createdAt ? 1 : -1 }
    ];

    return (
        <DataTable
            // @ts-ignore
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            persistTableHead={true}
            progressPending={loading || false}
            progressComponent={<LoadingTable />}
            noDataComponent={
                <Stack align='center' justify='center' p={10}>
                    <Image src={"/emptyData.png"} mah={150} w={"auto"} />
                    <Text size='14px' c={"#667085"}>{t('tables.emptyLable')}</Text>
                </Stack>
            }

            pagination={true}
            paginationComponentOptions={{
                rowsPerPageText: t('tables.rangeSeparatorText'),
                rangeSeparatorText: t('tables.rangeSeparatorText')
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 200]}
            paginationPerPage={100}
        />
    );
};


const LoadingTable = () => {
    const numRows = [0,0,0];
    return (
        <Stack w={"100%"} p={0} m={0} gap={0}>
            {numRows.map((item, index) => (
                <RowSkeleton key={index}/>
            ))}
        </Stack>
    )
}

const RowSkeleton = () => {
    
    return (
        <Group w={"100%"} miw={"100%"} mih={48} align='stretch' gap={0} wrap='nowrap' style={{borderBottom: "2px solid #E0E2E7"}}>
            <Group h={60} miw={"170px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"150px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
        </Group>
    );
}