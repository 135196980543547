import {useMantineTheme, Grid} from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import {Notyf} from "notyf";
import { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer'
import dayjs from 'dayjs';

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

Font.register({
    family: "Cairo",
    fonts: [
        {
            src: "/fonts/Cairo/Cairo-Regular.ttf",
            fontStyle: "normal",
            fontWeight: 400,
        },
        {
            src: "/fonts/Cairo/Cairo-Medium.ttf",
            fontStyle: "normal",
            fontWeight: 700,
        },
    ]
});

const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      fontFamily: "Cairo",
      padding: "1cm",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
});

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
} & ModalProps;

export const ShowIvoiceSites = ({data, ...props}: Props) => {
    const {colors} = useMantineTheme()
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    
    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal {...props} onClose={closeModal} loading={loading} size="xl" fullScreen  >
            <PDFViewer width={"100%"} style={{height: "calc(100vh - 6.3px)", border: "none"}} >
                <Document>
                    <Page size="A4"  style={styles.page}>
                        {/* header */}
                        <View style={{width: "100%", height: "auto", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            <View style={{height: "2cm", display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                {/*left section*/}
                                <Image style={{ height: "1.5cm"}} src={"/Bi3li_logo.png"} />

                                {/*right section*/}
                                <View style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-end"}}>
                                    <Text style={{ fontSize: 23, color: "#575757" }}>فاتورة تسليم العمولة</Text>
                                    <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: -4}}>
                                        <Text style={{fontSize: 11, color: "#707070", margin: 2,}}>
                                            {dayjs().format("DD/MM/YYYY HH:mm:ss")} :
                                        </Text>
                                        <Text style={{fontSize: 12, color: "#909090", margin: 2, textAlign: "right"}}>حررت بتاريخ</Text>
                                    </View>
                                </View>
                            </View>

                            {/* section 1 */}
                            <View style={{marginTop: "2cm"}}>
                                {/* product table */}
                                <View style={{border: `0.5 solid ${colors.gray[3]}`, marginTop: "32px"}}>
                                    {/* table header */}
                                    <View style={{display: "flex", flexDirection: "row"}}>
                                        <View style={{flex: "1", backgroundColor: colors.gray[2], height: "32px", justifyContent: "center", alignItems: "center", border: `0.5 solid #fff`, flexDirection: "row"}}>
                                            <Text style={{color: "#9E9E9E", fontSize: 11 }}>العمولة</Text>
                                        </View>

                                        <View style={{flex: "2", backgroundColor: colors.gray[2], height: "32px", justifyContent: "center", alignItems: "flex-end", border: `0.5 solid #fff`}}>
                                            <Text style={{color: "#9E9E9E", fontSize: 11}}>العميل</Text>
                                        </View>

                                        <View style={{flex: "0.5", backgroundColor: colors.gray[2], height: "32px", justifyContent: "center", alignItems: "center", border: `0.5 solid #fff`}}>
                                            <Text style={{color: "#9E9E9E", fontSize: 11}}>#</Text>
                                        </View>
                                    </View>

                                    {/* table content */}
                                    {data?.sites?.map((item: any, index: number) => (
                                        <View key={item?._id} style={{display: "flex", flexDirection: "row"}}>
                                            <View style={{flex: "1", flexShrink: 0, height: "32px", justifyContent: "center", alignItems: "center", border: `0.5 solid ${colors.gray[3]}`}}>
                                                <Text style={{color: "#707070", fontSize: 11}}>
                                                    <Text style={{ fontSize: 9 }}> DA </Text>
                                                    {item?.price}
                                                </Text>
                                            </View>

                                            <View style={{flex: "2", flexShrink: 0, height: "32px", justifyContent: "center", alignItems: "flex-end", border: `0.5 solid ${colors.gray[3]}`}}>
                                                <Text style={{color: "#707070", fontSize: 11}}>
                                                    {item?.site}
                                                </Text>
                                            </View>

                                            <View style={{flex: "0.5", flexShrink: 0, height: "32px", justifyContent: "center", alignItems: "center", border: `0.5 solid ${colors.gray[3]}`}}>
                                                <Text style={{color: "#707070", fontSize: 11}}>{index + 1}</Text>
                                            </View>
                                        </View>
                                    ))}
                                </View>

                                {/* Total */}

                                <View>
                                    <View style={{display: "flex", flexDirection: "row", width: "60%", marginTop: "32px", marginLeft: "auto"}}>
                                        <View style={{flex: "1", flexShrink: 0,  height: "32px", justifyContent: "center", alignItems: "center", border: `0.5 solid ${colors.gray[3]}`}}>
                                            <Text style={{color: "#707070", fontSize: 12}}>
                                                <Text style={{ fontSize: 9 }}> DA </Text>
                                                {data?.sites?.reduce((accumulator: number, currentValue: any) => accumulator + parseFloat(currentValue?.price), 0)}
                                            </Text>
                                        </View>

                                        <View style={{flex: "1", flexShrink: 0,  height: "32px", justifyContent: "center", alignItems: "flex-end", border: `0.5 solid ${colors.gray[3]}`}}>
                                            <Text style={{color: "#707070", fontSize: 11}}>المبلغ المستحق</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </Modal>
    );
};